<template>
  <div>
    <vue-element-loading :active="loader" is-full-screen></vue-element-loading>
    <b-tabs v-model="tabIndex" lazy>
      <b-tab title="General" lazy>
        <b-card>
          <b-row>
            <b-col>
              <b-form-group label="Order No *" description="Order No cannot be duplicate and should be unique all the time">
                <b-form-input v-model="payload.orderNo" :disabled="isEdit" type="number" :state="!!payload.orderNo" placeholder="Enter Order No" trim> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Order Date *">
                <b-form-datepicker v-model="payload.orderDate" :state="!!payload.orderDate" placeholder="Select Order Date"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :state="!!payload.clientCode">
                <template #label>
                  <div class="d-flex">
                    <span>Client *</span>
                    <small class="ml-auto text-primary font-weight-bolder cursor-pointer" @click.stop="addClient"> Add New Client </small>
                  </div>
                </template>
                <v-select
                  v-model="payload.clientCode"
                  :options="parameter.clientList"
                  :clearable="false"
                  label="contactName"
                  :reduce="option => option.code"
                  placeholder="Select Client"
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Product *" :state="!!payload.productCode">
                <v-select
                  v-model="payload.productCode"
                  :options="parameter.productList"
                  label="description"
                  :clearable="false"
                  :reduce="x => x.code"
                  placeholder="Select Product"
                  @option:selected="productChanged"
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Brand Name *" description="Client's brand name">
                <b-form-input v-model="payload.brandName" :state="!!payload.brandName" placeholder="Enter Brand Name" trim></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Notes">
                <b-form-textarea v-model="payload.notes" placeholder="Order Notes ......" trim rows="15" max-rows="20"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <template #footer>
            <div class="d-flex">
              <b-button variant="primary" class="ml-auto" @click.stop="tabIndex++">Next</b-button>
            </div>
          </template>
        </b-card>
      </b-tab>

      <b-tab v-if="isBlogRoll" title="Domain" lazy>
        <b-card>
          <div class="d-flex">
            <div>
              <span>Selected Domain Count :</span>
              <span :class="table.domain.selectedRows.length < blogRollCount ? `text-danger` : `text-success`">
                {{ table.domain.selectedRows.length | number }}
              </span>
              <span> / </span>
              <span class="text-success">{{ blogRollCount | number }}</span>
            </div>
            <b-button variant="warning" class="ml-auto" @click.stop="randomDomain">Select Domain Randomly</b-button>
            <b-button variant="danger" class="ml-1" @click.stop="clearDomain">Clear Selected Domain</b-button>
          </div>
        </b-card>
        <b-card>
          <b-form-group label="Filter Domain" label-cols="3">
            <b-input-group>
              <b-input v-model="table.domain.filter" debounce="300"></b-input>
              <b-input-group-append>
                <b-button variant="primary" @click.stop="modal.domainFilter = true"> Filter Multiple </b-button>
                <b-button variant="danger" @click.stop="clearDomainFilter"> Clear Filter </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-table :items="filteredDomain" :fields="table.domain.fields" small striped selectable hover>
            <template #cell(select)="data">
              <b-form-checkbox
                v-model="table.domain.selectedRows"
                :disabled="table.domain.selectedRows.length >= blogRollTotal && !table.domain.selectedRows.includes(data.item.domainUrl)"
                :value="data.item.domainUrl"
              ></b-form-checkbox>
            </template>
          </b-table>

          <template #footer>
            <div class="d-flex">
              <b-button variant="primary" @click.stop="tabIndex--">Prev</b-button>
              <b-button variant="primary" class="ml-auto" @click.stop="tabIndex++">Next</b-button>
            </div>
          </template>
        </b-card>
      </b-tab>

      <b-tab v-if="isBlogRoll" title="Order Detail" lazy>
        <b-card>
          <b-row v-if="isBlogRoll">
            <b-col>
              <b-form-group label="Start Date *">
                <b-form-datepicker v-model="payload.startDate" :state="!!payload.startDate" placeholder="Select Start Date"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Drip Feed">
                <b-input-group>
                  <b-form-input v-model="dripFeed" placeholder="How Many Daily Dripfeed ?" trim> </b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" :disabled="!payload.startDate" @click.stop="applyDripFeed"> Apply </b-button>
                    <b-button variant="danger" @click.stop="clearDripFeed">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Target Url">
                <b-input-group>
                  <b-form-input v-model="targetUrl" placeholder="https://mymoneysite.com" trim> </b-form-input>
                  <b-form-input v-model="targetUrlCount" type="number" placeholder="How Many Row ?" trim></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" :disabled="!targetUrl" @click.stop="applyTargetUrl"> Add Target </b-button>
                    <b-button variant="danger" @click.stop="clearTargetUrl">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Keyword" description="Select Keyword to add and rel (if needed)">
                <b-input-group>
                  <b-form-input v-model="keyword" placeholder="Whats The Keyword ?"> </b-form-input>
                  <b-form-input v-model="keywordCount" type="number" placeholder="How Many Keywords ?" trim></b-form-input>
                  <b-form-select v-model="rel" :options="parameter.relList"></b-form-select>

                  <b-input-group-append>
                    <b-button variant="primary" :disabled="!keywordCount || !keyword" @click.stop="addKw">Add</b-button>
                    <b-button variant="info" @click.stop="modal.rotatingKeyword = true">Rotating</b-button>
                    <b-button variant="danger" @click.stop="clearKw">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="mx-auto">Order Detail</h3>
          </template>
          <b-table :items="table.orderDetail.items" :fields="detailField" small striped hover show-empty>
            <template #cell(seq)="data">{{ data.index + 1 }}</template>
            <template #cell(workDate)="data">
              <b-form-datepicker v-model="data.item.workDate" placeholder="Select Run Date"></b-form-datepicker>
            </template>
            <template #cell(anchor)="data">
              <b-input v-model="data.item.anchor" placeholder="Insert Target URL" trim></b-input>
            </template>
            <template #cell(rel)="data">
              <b-form-select v-model="data.item.rel" :options="parameter.relList"></b-form-select>
            </template>
            <template #cell(targetUrl)="data">
              <b-input v-model="data.item.targetUrl" placeholder="Insert Target URL" trim></b-input>
            </template>
          </b-table>
          <template #footer>
            <div class="d-flex">
              <b-button variant="primary" @click.stop="tabIndex--">Prev</b-button>
              <b-button variant="primary" class="ml-auto" @click.stop="tabIndex++">Next</b-button>
            </div>
          </template>
        </b-card>
      </b-tab>

      <b-tab title="Summary" :disabled="!summaryEnabled">
        <b-row>
          <b-col>
            <b-card class="h-100">
              <b-form-group label="Order #" label-cols="3">
                <b-input v-model="payload.orderNo" disabled></b-input>
              </b-form-group>
              <b-form-group label="Order Date" label-cols="3">
                <b-form-datepicker v-model="payload.orderDate" disabled></b-form-datepicker>
              </b-form-group>
              <b-form-group v-if="payload.productCode" label="Product" label-cols="3">
                <b-input :value="selectedProduct.description" disabled></b-input>
              </b-form-group>
              <b-form-group v-if="payload.clientCode" label="Client" label-cols="3">
                <b-input :value="selectedClient.contactName" disabled></b-input>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col v-if="isBlogRoll">
            <b-card title="Target URL" class="h-100">
              <div v-for="x in targetUrlList" :key="x">
                <div class="text-primary">{{ x }}</div>
              </div>
            </b-card>
          </b-col>
          <b-col v-if="isBlogRoll">
            <b-card title="Keyword Summary" class="h-100">
              <b-table :items="keywordSummary" small striped hover show-empty>
                <template #cell(keyword)="data">{{ data.item.keyword }}</template>
                <template #cell(count)="data">{{ data.item.count }}</template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-card v-if="payload.productCode">
              <b-form-group label="Price" label-cols="3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text style="width: 100px">{{ selectedProduct.sellPriceCur }}</b-input-group-text>
                  </template>
                  <vue-numeric v-model="selectedProduct.sellPrice" class="form-control" :precision="2" disabled></vue-numeric>
                </b-input-group>
              </b-form-group>
              <b-form-group label="Discount" label-cols="3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text style="width: 100px">{{ payload.discountCur }}</b-input-group-text>
                  </template>
                  <vue-numeric v-model="payload.discount" class="form-control" :precision="2"></vue-numeric>
                </b-input-group>
              </b-form-group>
              <template #footer>
                <b-button variant="success" size="lg" block @click.stop="submit">SUBMIT ORDER</b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <b-modal v-model="modal.client" centered no-close-on-backdrop hide-footer>
      <client-detail :agentCode="this.agent.code" @onSaveSuccess="saveClientSuccess" @onClose="closeClientModal"></client-detail>
    </b-modal>

    <b-modal v-model="modal.domainFilter" centered hide-footer>
      <b-form-textarea v-model="table.domain.filterMultipleText" placeholder="Input domain here .. 1 domain per row" trim rows="10" max-rows="25"></b-form-textarea>

      <hr />

      <div class="d-flex">
        <b-button variant="primary" class="ml-auto" @click.stop="filterMultipleDomain">Submit</b-button>
      </div>
    </b-modal>

    <b-modal v-model="modal.rotatingKeyword" centered hide-footer>
      <b-form-textarea v-model="rotatingKeywordString" placeholder="Input keyword here .. 1 keyword per row" trim rows="10" max-rows="25"></b-form-textarea>

      <hr />

      <div class="d-flex">
        <b-button variant="primary" class="ml-auto" @click.stop="rotateKeyword">Submit</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import ClientDetail from "@/views/client/Detail.vue";

export default {
  name: `WorkorderDetail`,
  props: {
    order: {
      type: Object,
      default: null,
    },
    agent: {
      type: Object,
      required: true,
    },
  },
  components: { ClientDetail },
  data() {
    return {
      isEdit: false,
      parameter: {
        clientList: [],
        productList: [],
        domainList: [],
        relList: [
          { value: null, text: `` },
          { value: `nofollow`, text: `nofollow` },
          { value: `sponsored`, text: `sponsored` },
          { value: `ugc`, text: `ugc` },
        ],
      },
      dripFeed: null,
      targetUrl: null,
      targetUrlCount: null,
      keyword: null,
      keywordCount: null,
      rel: null,
      payload: {
        orderNo: null,
        clientCode: null,
        productCode: null,
        brandName: null,
        orderDate: null,
        startDate: null,
        discount: 0,
        discountCur: `IDR`,
        orderDetail: [],
      },
      table: {
        domain: {
          items: [],
          fields: [
            { key: `select`, label: ``, thStyle: `width: 40px` },
            { key: `domainUrl`, label: `Domain`, sortable: true },
          ],
          filter: null,
          filterMultipleText: null,
          filterMultipleArray: [],
          selectedRows: [],
        },
        orderDetail: {
          items: [],
          fields: [
            { key: "seq", label: "#" },
            { key: "domain", label: "Domain" },
            { key: "anchor", label: "Anchor" },
            { key: "rel", label: "Rel" },
            { key: "targetUrl", label: "Target" },
            { key: "workDate", label: "Run Date" },
          ],
        },
      },
      rotatingKeywordString: null,
      rotatingKeyword: [],
      modal: { client: false, domainFilter: false, rotatingKeyword: false },
      tabIndex: 0,
      loader: false,
    };
  },

  computed: {
    isBlogRoll() {
      if (!this.payload.productCode) return false;
      return this.parameter.productList.find(x => x.code === this.payload.productCode).productTypeCode == "1";
    },
    isBlogPost() {
      if (!this.payload.productCode) return false;
      return this.parameter.productList.find(x => x.code === this.payload.productCode).productTypeCode == "2";
    },
    isDiversity() {
      if (!this.payload.productCode) return false;
      return this.parameter.productList.find(x => x.code === this.payload.productCode).productTypeCode == "3";
    },
    isGuestPost() {
      if (!this.payload.productCode) return false;
      return this.parameter.productList.find(x => x.code === this.payload.productCode).productTypeCode == "4";
    },
    selectedProduct() {
      if (this.payload.productCode == null) return null;
      return this.parameter.productList.find(x => x.code === this.payload.productCode);
    },
    selectedClient() {
      if (this.payload.clientCode == null) return null;
      return this.parameter.clientList.find(x => x.code === this.payload.clientCode);
    },
    blogRollCount() {
      if (!this.isBlogRoll) return 0;
      else return this.parameter.productList.find(x => x.code === this.payload.productCode).blogRollCount;
    },
    filteredDomain() {
      let retVal = this.table.domain.items;
      if (!!this.table.domain.filter) retVal = this.table.domain.items.filter(x => x.domainUrl.includes(this.table.domain.filter));
      if (this.table.domain.filterMultipleArray.length > 0) retVal = this.table.domain.items.filter(x => this.table.domain.filterMultipleArray.includes(x.domainUrl));
      return retVal;
    },
    blogRollTotal() {
      if (!this.payload.productCode) return 0;
      else return this.parameter.productList.find(x => x.code === this.payload.productCode).blogRollCount;
    },
    detailField() {
      if (this.isBlogRoll) return this.table.orderDetail.fields;
      else if (this.isBlogPost) return this.table.orderDetail.fields.filter(x => x.key !== "workDate" && x.key !== "rel");
      else if (this.isGuestPost) return this.table.orderDetail.fields.filter(x => x.key !== "workDate" && x.key !== "rel");
      else return this.table.orderDetail.fields.filter(x => x.key !== "domain" && x.key !== "workDate");
    },
    orderDetailCompleted() {
      return this.table.orderDetail.items.every(item => item.domain && item.anchor && item.targetUrl && item.workDate);
    },
    summaryEnabled() {
      if (!this.isBlogRoll) return !!this.payload.orderNo && !!this.payload.orderDate && !!this.payload.clientCode && !!this.payload.productCode && !!this.payload.brandName;
      else {
        return !!this.payload.orderNo && !!this.payload.orderDate && !!this.payload.clientCode && !!this.payload.productCode && !!this.payload.brandName && this.orderDetailCompleted;
      }
    },
    targetUrlList() {
      if (!this.isBlogRoll) return [];
      let targetUrlList = this.table.orderDetail.items.map(x => x.targetUrl);
      let distinctItems = [...new Set(targetUrlList)];
      return distinctItems;
    },
    keywordSummary() {
      let keywordGroupWithCount = this.table.orderDetail.items.reduce((acc, item) => {
        if (!item.anchor) return acc;
        if (!acc[item.anchor]) acc[item.anchor] = 0;
        acc[item.anchor]++;
        return acc;
      }, {});
      return Object.entries(keywordGroupWithCount).map(([key, value]) => ({ keyword: key, count: value }));
    },
  },

  watch: {
    "table.domain.selectedRows": {
      handler: function (val) {
        this.table.orderDetail.items = val.map(x => ({ domain: x, anchor: null, targetUrl: null, workDate: null }));
        this.table.orderDetail.items.forEach(item => {
          const payloadItem = this.payload.orderDetail.find(payloadItem => payloadItem.domain === item.domain);
          if (payloadItem) {
            item.anchor = payloadItem.anchor;
            item.targetUrl = payloadItem.targetUrl;
            item.workDate = payloadItem.workDate;
            item.rel = payloadItem.rel;
          }
        });
      },
      deep: true,
    },
  },

  async mounted() {
    this.loadClient();
    this.loadProduct();
    this.loadDomain();
    if (this.order) await this.loadOrder();
  },

  methods: {
    async loadClient() {
      this.payload.clientCode = null;
      this.parameter.clientList = await api.get(`client`, { agentCode: this.agent.code });
    },
    async loadProduct() {
      this.payload.productCode = null;
      this.parameter.productList = await api.get(`product/agentproduct`, { agentCode: this.agent.code });
    },
    async loadDomain() {
      try {
        this.table.domain.items = await api.get(`domain`, { agentCode: this.agent.code, onlyValid: true });
      } catch (error) {
        this.showToast(`error`, error);
      }
    },
    async loadOrder() {
      this.loader = true;
      try {
        this.isEdit = true;
        this.payload = await api.get(`order/${this.order.orderNo}`);
        this.payload.orderNo = this.order.orderNo.split("|")[1];
        this.table.domain.selectedRows = this.payload.orderDetail.map(x => x.domain);

        if (this.order.isExtend) {
          this.payload.orderNo = null;
          this.payload.orderDate = this.$moment(this.order.dueDate).add(1, "days").format("YYYY-MM-DD");
          this.payload.notes = `Extend order ${this.order.orderNo.split("|")[1]}`;
          this.isEdit = false;
        }
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async productChanged() {
      this.table.orderDetail.items = [];
      this.table.domain.selectedRows = [];
      this.payload.discountCur = this.selectedProduct.sellPriceCur;
    },
    addClient() {
      this.modal.client = true;
    },
    addKw() {
      let count = 0;
      const itemsLength = this.table.orderDetail.items.length;
      for (let i = 0; i < itemsLength; i++) {
        let x = this.table.orderDetail.items[i];
        if (!x.anchor) {
          x.anchor = this.keyword;
          x.rel = this.rel;
          count++;
        }
        if (count >= this.keywordCount) break;
      }

      this.keyword = null;
      this.rel = null;
      this.keywordCount = null;
    },
    clearKw() {
      this.table.orderDetail.items.forEach(item => {
        item.anchor = null;
      });
    },
    saveClientSuccess(client) {
      this.loadClient();
      this.payload.clientCode = client.code;
      this.modal.client = false;
    },
    closeClientModal() {
      this.modal.client = false;
    },
    randomDomain() {
      let availableDomains = this.filteredDomain.filter(domain => !this.table.domain.selectedRows.includes(domain.domainUrl));

      let domainCountNeeded = Math.min(this.blogRollTotal - this.table.domain.selectedRows.length, availableDomains.length);
      let randomDomains = [];
      for (let i = 0; i < domainCountNeeded; i++) {
        let randomIndex = Math.floor(Math.random() * availableDomains.length);
        randomDomains.push(availableDomains[randomIndex].domainUrl);
        availableDomains.splice(randomIndex, 1);
      }
      this.table.domain.selectedRows.push(...randomDomains);
    },
    clearDomain() {
      this.table.domain.selectedRows = [];
    },
    filterMultipleDomain() {
      this.table.domain.filter = null;
      this.table.domain.filterMultipleArray = this.table.domain.filterMultipleText
        .split("\n")
        .map(x => x.trim())
        .filter(x => x !== "");
      this.modal.domainFilter = false;
    },
    clearDomainFilter() {
      this.table.domain.filterMultipleArray = [];
      this.table.domain.filterMultipleText = null;
      this.table.domain.filter = null;
    },
    applyDripFeed() {
      if (!this.dripFeed) return;
      let dripFeed = parseInt(this.dripFeed);
      let startDate = new Date(this.payload.startDate);
      let workDate = new Date(startDate);
      let d = 0;

      for (let i = 0; i < this.table.orderDetail.items.length; i++) {
        let currentDate = new Date(workDate);

        this.table.orderDetail.items[i].workDate = new Date(currentDate);
        d++;
        if (d == dripFeed) {
          d = 0;
          workDate.setDate(workDate.getDate() + 1);
        }
      }
      this.dripFeed = null;
    },
    clearDripFeed() {
      for (let i = 0; i < this.table.orderDetail.items.length; i++) {
        this.table.orderDetail.items[i].workDate = null;
      }
    },
    applyTargetUrl() {
      if (!this.targetUrl) return;
      const urlRegex = /^(https?:\/\/)?(([\w.-]+)\.([a-z]{2,})|(\d{1,3}\.){3}\d{1,3})(\/\S*)?$/i;
      if (!urlRegex.test(this.targetUrl)) {
        this.showToast(`error`, `Invalid URL format`);
        return;
      }
      let count = 0;
      const itemsLength = this.table.orderDetail.items.length;
      for (let i = 0; i < itemsLength; i++) {
        let x = this.table.orderDetail.items[i];
        if (!x.targetUrl) {
          x.targetUrl = this.targetUrl;
          count++;
        }
        if (count >= this.targetUrlCount) break;
      }

      this.targetUrl = null;
      this.targetUrlCount = null;
    },
    clearTargetUrl() {
      for (let i = 0; i < this.table.orderDetail.items.length; i++) {
        this.table.orderDetail.items[i].targetUrl = null;
      }
    },
    rotateKeyword() {
      if (!this.rotatingKeywordString) return;
      this.rotatingKeyword = this.rotatingKeywordString
        .split("\n")
        .map(x => x.trim())
        .filter(x => x !== "");
      let count = 0;
      const itemsLength = this.table.orderDetail.items.length;
      for (let i = 0; i < itemsLength; i++) {
        let x = this.table.orderDetail.items[i];
        if (!x.anchor) {
          x.anchor = this.rotatingKeyword[count];
          x.rel = this.rel;
          count++;
        }
        if (count >= this.rotatingKeyword.length) count = 0;
      }

      this.rotatingKeywordString = null;
      this.modal.rotatingKeyword = false;
    },
    async submit() {
      let confirmed = await this.showConfirm(`Please Check Order Data and Confirm`);
      if (!confirmed) return;

      this.loader = true;
      let orderDetail = this.table.orderDetail.items.map(x => ({
        domain: x.domain,
        anchor: x.anchor,
        targetUrl: x.targetUrl,
        workDate: x.workDate,
        rel: x.rel,
      }));
      this.payload.details = orderDetail;
      this.payload.agentCode = this.agent.code;

      try {
        if (this.isEdit) {
          this.payload.orderNo = this.order.orderNo;
          await api.put(`order/${this.order.orderNo}`, this.payload);
        } else if (this.order && this.order.isExtend) {
          this.payload.extendedOrderNo = this.order.orderNo;
          await api.post(`order`, this.payload);
        } else {
          await api.post(`order`, this.payload);
        }
        this.$emit(`onSaveSuccess`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
